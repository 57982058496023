import './Welcome.scss'

export default function Welcome() {
  return (
    <div className="welcome-wrapper" id="home">
      <div className="welcome-text-wrapper">
        <p>Prashant Rayamajhi</p>
        <h1>I am a Full Stack Web Developer</h1>
        <p className="description">
          I am Prashant Rayamajhi, a self taught MERN stack developer from
          Nepal. I love drinking coffee and coding. I develop scalable and
          interesting web and mobile applications.
        </p>
        <div className="btn-wrapper">
          <a href="/#about" className="link" style={{ textDecoration: 'none' }}>
            <button className="btn about">About me</button>
          </a>
          <a
            href="/#contact"
            className="link"
            style={{ textDecoration: 'none' }}
          >
            <button className="btn">Contact Me</button>
          </a>
        </div>
      </div>
    </div>
  )
}
