import './Service.scss'
import Heading from './../Heading/Heading'
import { Code, Computer, Settings } from '@material-ui/icons'
import Container from '../Container/Container'

export default function Services() {
  return (
    <section className="services-wrapper" id="skills">
      <Container>
        <Heading heading="My Skills" subHeading="Languages and tools I use" />
        <div className="services">
          <div className="card">
            <div className="icon-wrapper">
              <Computer className="icon" />
            </div>
            <div className="card-title">
              <h2>Frontend</h2>
            </div>
            <div className="list">
              <p className="item">HTML</p>
              <p className="item">CSS / SASS</p>
              <p className="item">Bootsrap</p>
              <p className="item">Material UI</p>
              <p className="item">Javascript</p>
              <p className="item">React.js</p>
              <p className="item">Next.js</p>
              <p className="item">Gatsby.js</p>
            </div>
          </div>
          <div className="card">
            <div className="icon-wrapper">
              <Code className="icon" />
            </div>
            <div className="card-title">
              <h2>Backend</h2>
            </div>
            <div className="list">
              <p className="item">Javascript</p>
              <p className="item">Node.js</p>
              <p className="item">Express.js</p>
              <p className="item">PostgreSQL</p>
              <p className="item">MySQL</p>
              <p className="item">MongoDB</p>
              <p className="item">TypeORM, Sequelize</p>
              <p className="item">Mongoose</p>
            </div>
          </div>
          <div className="card">
            <div className="icon-wrapper">
              <Settings className="icon" />
            </div>
            <div className="card-title">
              <h2>Tools</h2>
            </div>
            <div className="list">
              <p className="item">Git</p>
              <p className="item">Github</p>
              <p className="item">Jest</p>
              <p className="item">AWS</p>
              <p className="item">Linux</p>
              <p className="item">Redux</p>
              <p className="item">Stackoverflow</p>
              <p className="item">Cloudinary</p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
