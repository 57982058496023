import AboutImage from './../../images/about.svg'
import './About.scss'
import Container from '../Container/Container'
import Heading from './../Heading/Heading'

export default function About() {
  return (
    <div className="about-wrapper" id="about">
      <Container>
        {/* <div className={'flex items-center flex-column-sm'}> */}
        <div className="about-wrapper">
          <div className="about-details">
            <Heading heading="ABOUT ME" subHeading="Who am I ?" />
            <div className="about-description">
              <p>
                I am Prashant Rayamajhi, a full stack web developer from Nepal.
                I work with MERN stack. I also develop mobile applications using
                react native. I am a passionate self taught developer and I
                create interesting and scalable web applications.
              </p>
            </div>
            <div className="btn-wrapper">
              <a
                href="/#skills"
                className="link"
                style={{ textDecoration: 'none' }}
              >
                <button className="btn about">My Skills</button>
              </a>
            </div>
          </div>
          <div className="about-image">
            <img src={AboutImage} alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}
