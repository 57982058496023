import { useState } from 'react'
import PortfolioCard from './PortfolioCard'
import Container from './../Container/Container'
import Heading from './../Heading/Heading'
import './Portfolio.scss'

// images
import CarHeram from './../../images/car.jpg'
import SocialMedia from './../../images/social.jpg'
import Blog from './../../images/blog.jpg'
import Chat from './../../images/chat.jpg'
import Sawari from './../../images/sawari.jpg'
import URL from './../../images/url.jpg'

export default function Portfolio() {
  // state for portfolio items
  const [tag, setTag] = useState('all')

  // update tag state
  const onTabClick = (id: string) => {
    setTag(id)
  }

  const portfolioItems = [
    {
      title: 'Car Heram',
      img: CarHeram,
      link: 'https://www.carheram.com/',
      github: 'https://github.com/prashantrayamajhi/next-car-sansar',
    },
    {
      title: 'Social Media',
      img: SocialMedia,
      link: 'https://socialmediaappclient.vercel.app/',
      github: 'https://github.com/prashantrayamajhi/social-client',
    },
    {
      title: 'Personal Blog',
      img: Blog,
      link: 'https://blogs.prashantrayamajhi.vercel.app/',
      github: 'https://github.com/prashantrayamajhi/blog-client',
    },
    {
      title: 'Chat App',
      img: Chat,
      link: 'https://chatcrap.herokuapp.com/',
      github: 'https://github.com/prashantrayamajhi/chat-app',
    },
    {
      title: 'Sawari Motors',
      img: Sawari,
      link: 'https://sawarimotors.com/',
      github: 'https://github.com/prashantrayamajhi/sawari-motors-client',
    },
    {
      title: 'URL Shortener',
      img: URL,
      link: 'https://shorter-ten.vercel.app/',
      github: 'https://github.com/prashantrayamajhi/url-shortener-client',
    },
  ]

  const mappedData = portfolioItems.map((project, index: number) => {
    return (
      <PortfolioCard
        key={index}
        title={project.title}
        img={project.img}
        link={project.link}
        github={project.github}
      />
    )
  })
  return (
    <div className="portfolio-wrapper" id="projects">
      <Container>
        <Heading heading="Projects" subHeading="Here are some of my projects" />
        <div className="portfolio-items">{mappedData}</div>
      </Container>
    </div>
  )
}
