import './Navbar.scss'

import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import navBrand from '../../images/navbrand.webp'

export default function HomeNav() {
  // state for responsive navbar
  const [isResponsive, setIsResponsive] = useState<boolean>(false)

  useEffect(() => {
    if (isResponsive) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'scroll'
    }
  }, [isResponsive])

  const closeResponsive = () => {
    if (isResponsive) {
      setIsResponsive(!isResponsive)
    }
  }

  const toggleResponsive = () => {
    setIsResponsive(!isResponsive)
  }

  return (
    <div className={'full-navbar-wrapper'}>
      <nav className="navbar-wrapper">
        <div className="navbar">
          <div className="nav-brand">
            {/* <img src={navBrand} alt={'trostrum'} /> */}
            <Link to="/" className="nav-brand-link">
              <h3>Prashant</h3>
            </Link>
          </div>
          <div className={`nav-list ${isResponsive ? 'responsive' : ''}`}>
            <a href="/#home" className="nav-item" onClick={closeResponsive}>
              Home
            </a>
            <a href="/#about" className="nav-item" onClick={closeResponsive}>
              About
            </a>
            <a href="/#skills" className="nav-item" onClick={closeResponsive}>
              Skills
            </a>
            <a href="/#projects" className="nav-item" onClick={closeResponsive}>
              Projects
            </a>
            <a
              href="/#testimonials"
              className="nav-item"
              onClick={closeResponsive}
            >
              Testimonials
            </a>
            <a href="/#contact" className="nav-item" onClick={closeResponsive}>
              Contact
            </a>
          </div>
          <div
            className={`hamburger ${isResponsive ? 'open' : ''}`}
            onClick={toggleResponsive}
          >
            <div className="div1" />
            <div className="div2" />
            <div className="div3" />
          </div>
        </div>
      </nav>
    </div>
  )
}
