import { Link } from 'react-router-dom'
import { Visibility } from '@material-ui/icons'
interface PortfolioInterface {
  title: string
  img: string
  link: string
  github: string
}

export default function PortfolioCard(props: PortfolioInterface) {
  return (
    <div className="portfolio">
      <div className="overlay">
        <h3>{props.title}</h3>
        <div className="actions">
          <a
            href={props.link}
            target="_blank"
            rel="noreferrer"
            className="link preview"
          >
            Preview
          </a>
          <a
            href={props.github}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            Github
          </a>
        </div>
      </div>
      <img src={props.img} alt={props.title} />
    </div>
  )
}
