import { useForm } from '@formspree/react'
import React, { useState } from 'react'
import Container from './../Container/Container'
import Heading from './../Heading/Heading'
import { LocationCity, Smartphone, Mail } from '@material-ui/icons'
import './Contact.scss'

export default function Contact() {
  const [state, handleSubmit] = useForm('xleaaqae')
  if (state.succeeded) {
    window.location.reload()
  }
  return (
    <div className="contact-wrapper" id="contact">
      <Container>
        <Heading heading="Contact Me" subHeading="Keep in Touch" />
        <div className="contact">
          <form onSubmit={handleSubmit}>
            <div className="input-wrapper">
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="input"
                required
              />
            </div>
            <div className="input-wrapper">
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="input"
                required
              />
            </div>
            <div className="input-wrapper">
              <textarea
                placeholder="Message"
                name="message"
                rows={8}
                className="input"
                required
              />
            </div>
            <button
              type="submit"
              className="submit-btn"
              disabled={state.submitting}
            >
              Send Now!
            </button>
          </form>
          <div className="contact-details">
            <div className="contact-item">
              <LocationCity className="icon" />
              <div className="text-wrapper">
                <p>Nepal, Kathmandu</p>
              </div>
            </div>
            <div className="contact-item">
              <Smartphone className="icon" />
              <div className="text-wrapper">
                <p>+977-9840168144</p>
              </div>
            </div>
            <div className="contact-item">
              <Mail className="icon" />
              <div className="text-wrapper">
                <p>prashantrayamajhi85@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
