import './Footer.scss'
import { Facebook, LinkedIn, GitHub, Instagram } from '@material-ui/icons'

export default function Footer() {
  const date = new Date().getFullYear()
  return (
    <footer className="footer-wrapper">
      <div className="pre-footer">
        <h3>PRASHANT RAYAMAJHI</h3>
        <div className="social-wrapper">
          <a
            href="https://github.com/prashantrayamajhi"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <GitHub className="icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/prashant-rayamajhi-0087311ab/"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <LinkedIn className="icon" />
          </a>
          <a
            href="https://www.instagram.com/prashant_rayamajhi/"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <Instagram className="icon" />
          </a>
          <a
            href="https://www.facebook.com/prashant.rayamajhi.71/"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <Facebook className="icon" />
          </a>
        </div>
      </div>
      <div className="footer">
        <p>© {date} All Rights Reserved By Prashant Rayamajhi</p>
      </div>
    </footer>
  )
}
