import { BrowserRouter as Router } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'

import Home from './Home/Home'

export default function RouterComponent() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
        </Switch>
      </Router>
    </>
  )
}
